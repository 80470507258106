/* =Foundation */
:root {
    --background-color: #1f2a36;
    --text-color: #ffce2e;
    --error: #ff9fb4;
    --subtle: #eee;
}

@font-face {
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: normal;
    src:
        url('https://static.internetstiftelsen.se/internetstiftelsen/fonts/HKGrotesk-Light.woff2') format('woff2'),
        url('https://static.internetstiftelsen.se/internetstiftelsen/fonts/HKGrotesk-Light.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 700;
    src:
        url('https://static.internetstiftelsen.se/internetstiftelsen/fonts/HKGrotesk-Medium.woff2') format('woff2'),
        url('https://static.internetstiftelsen.se/internetstiftelsen/fonts/HKGrotesk-Medium.woff') format('woff');
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
}

html {
    background: var(--background-color);
    font-family: HK Grotesk, sans-serif;
    font-size: 100%;
    user-select: none;
    box-sizing: border-box;
    font-variant-ligatures: no-common-ligatures;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body, html {
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    font-size: 4vw;
}

img {
    max-width: 100%;
    height: auto;
}

/* =Layout */
.viewport {
    width: 100vw;
    height: 100vh;
    transform: translate3d(0, 0, 0);
    font-size: 4vw;
    text-align: center;
    color: var(--text-color);
}

.view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    transition: 0.3s opacity;
}

.view.hidden {
    opacity: 0;
}

.logotype {
	margin-bottom: 2rem;
}

.view-content {
    width: 100%;
    padding: 10%;
}

.float {
    position: fixed;
    top: 5%;
    left: 5%;
    z-index: 100;
    display: flex;
    align-items: center;
}

.back.hidden {
    display: none;
}

.close {
    font-size: 2vw;
}

.inactivity-message {
    color: var(--text-color);
    opacity: 1;
    transition: 0.3s opacity;
    padding-left: 2vw;
}

.inactivity-bar.hidden {
    opacity: 0;
}

/* =Typography */
h1 {
    font-size: 7vw;
    font-weight: normal;
}

.supersize {
	font-size: 5.5vw;
}

ol, ul {
    padding-left: 10%;
}

ol li, ul li {
	padding-top: 1%;
	padding-bottom: 1%;
}

.headline {
    text-align: left;
    margin-bottom: 0.5em;
	line-height: 1;
}

li {
    text-align: left;
}

a {
    color: var(--text-color);
}

/* =Form */
input:not([type="checkbox"]) {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    border: none;
    padding: 0.5em;
    font-size: 4vw;
    border-radius: 0.25rem;
    outline: none;
}

label {
    font-size: 4vw;
    text-align: left;
}

button {
    display: inline-block;
    border: none;
    padding: 0.5em 1.5em;
    font-size: 4vw;
    background: var(--text-color);
    color: var(--background-color);
    border-radius: 0.25rem;
}

.form-control {
    margin-bottom: 0.5em;
}

.keyboard-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1vw;
    transition: 0.3s transform;
    transform: translate3d(0, 0, 0);
}

.keyboard-wrapper.hidden {
    transform: translate3d(0, 100%, 0);
}

.input-error,
.error {
    display: block;
    text-align: left;
    background: var(--error);
    color: var(--background-color);
    font-size: 0.6em;
    padding: 0.5em;
    z-index: 1;
    font-weight: bold;
}

.error {
    margin-bottom: 0.25em;
    border-radius: 0.25rem;
}

.input-error,
.input-help {
    border-radius: 0 0 0.25rem 0.25rem;
    margin-top: -0.2rem;
}

.input-error ul,
.input-help ul {
    list-style-type: disc;
    margin: 0.2em 0 0 1.5em;
}

.checkbox + .input-error,
.checkbox + .input-help {
    border-radius: 0.25rem;
    margin-top: 0.25em;
}

.input-error:empty,
.error {
    display: none;
}

.input-help {
    display: block;
    text-align: left;
    background: var(--subtle);
    color: var(--background-color);
    font-size: 0.6em;
    padding: 0.5em;
    z-index: 1;
    font-weight: bold;
}

.checkbox {
    position: relative;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    top: 0;
}

.checkbox label {
    display: flex;
    align-items: flex-start;
}

.checkbox label::before {
    content: '';
    display: block;
    position: relative;
    top: 0.15em;
    width: 4vw;
    height: 4vw;
    background: #fff;
    border-radius: 0.25rem;
    margin-right: 1vw;
}

.checkbox label::after {
    content: '';
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    font-size: 2.3vw;
    border: solid #fff;
    border-width: 0 0.2em 0.2em 0;
    height: 1em;
    transform: rotate(45deg);
    width: 0.5em;
}

.checkbox input:checked + label::before {
    background: var(--text-color);
}

.checkbox input:checked + label::after {
    border-color: var(--background-color);
}

/* =Modal */
.modal {
    position: fixed;
    top: 5%;
    bottom: 5%;
    left: 5%;
    right: 5%;
    padding: 2vw;
    background: #fff;
    color: #000;
    border-radius: 0.25rem;
    overflow: auto;
    z-index: 200;
    box-shadow: 0 0 5vw rgba(0, 0, 0, 0.2);
    font-size: 2vw;
}

.modal-content {
    margin: 4vh 0;
}

.modal.hidden {
    display: none;
}

.modal p, .modal h1, .modal h2 {
    margin-bottom: 1rem;
}

.modal ul {
    margin: 0 1rem 2rem;
}

/* =Misc */
.hg-theme-default .hg-button {
    padding: 1em 0 !important;
    font-size: 4vw !important;
}

.offline {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(31, 42, 54, 0.9);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    color: var(--text-color);
    opacity: 0;
    transform: translate(0, 100%);
}

.offline.visible {
    opacity: 1;
    transform: translate(0, 0);
    transition: 0.3s opacity;
}
