:root {
  --background-color: #1f2a36;
  --text-color: #ffce2e;
  --error: #ff9fb4;
  --subtle: #eee;
}

@font-face {
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: normal;
  src: url("https://static.internetstiftelsen.se/internetstiftelsen/fonts/HKGrotesk-Light.woff2") format("woff2"), url("https://static.internetstiftelsen.se/internetstiftelsen/fonts/HKGrotesk-Light.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: 700;
  src: url("https://static.internetstiftelsen.se/internetstiftelsen/fonts/HKGrotesk-Medium.woff2") format("woff2"), url("https://static.internetstiftelsen.se/internetstiftelsen/fonts/HKGrotesk-Medium.woff") format("woff");
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
}

html {
  background: var(--background-color);
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  font-variant-ligatures: no-common-ligatures;
  font-family: HK Grotesk, sans-serif;
  font-size: 100%;
}

*, :before, :after {
  box-sizing: inherit;
}

body, html {
  height: 100%;
  min-height: 100%;
  font-size: 4vw;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.viewport {
  text-align: center;
  color: var(--text-color);
  width: 100vw;
  height: 100vh;
  font-size: 4vw;
  transform: translate3d(0, 0, 0);
}

.view {
  opacity: 1;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  transition: opacity .3s;
  display: flex;
}

.view.hidden {
  opacity: 0;
}

.logotype {
  margin-bottom: 2rem;
}

.view-content {
  width: 100%;
  padding: 10%;
}

.float {
  z-index: 100;
  align-items: center;
  display: flex;
  position: fixed;
  top: 5%;
  left: 5%;
}

.back.hidden {
  display: none;
}

.close {
  font-size: 2vw;
}

.inactivity-message {
  color: var(--text-color);
  opacity: 1;
  padding-left: 2vw;
  transition: opacity .3s;
}

.inactivity-bar.hidden {
  opacity: 0;
}

h1 {
  font-size: 7vw;
  font-weight: normal;
}

.supersize {
  font-size: 5.5vw;
}

ol, ul {
  padding-left: 10%;
}

ol li, ul li {
  padding-top: 1%;
  padding-bottom: 1%;
}

.headline {
  text-align: left;
  margin-bottom: .5em;
  line-height: 1;
}

li {
  text-align: left;
}

a {
  color: var(--text-color);
}

input:not([type="checkbox"]) {
  z-index: 2;
  border: none;
  border-radius: .25rem;
  outline: none;
  width: 100%;
  padding: .5em;
  font-size: 4vw;
  display: block;
  position: relative;
}

label {
  text-align: left;
  font-size: 4vw;
}

button {
  background: var(--text-color);
  color: var(--background-color);
  border: none;
  border-radius: .25rem;
  padding: .5em 1.5em;
  font-size: 4vw;
  display: inline-block;
}

.form-control {
  margin-bottom: .5em;
}

.keyboard-wrapper {
  padding: 1vw;
  transition: transform .3s;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 0, 0);
}

.keyboard-wrapper.hidden {
  transform: translate3d(0, 100%, 0);
}

.input-error, .error {
  text-align: left;
  background: var(--error);
  color: var(--background-color);
  z-index: 1;
  padding: .5em;
  font-size: .6em;
  font-weight: bold;
  display: block;
}

.error {
  border-radius: .25rem;
  margin-bottom: .25em;
}

.input-error, .input-help {
  border-radius: 0 0 .25rem .25rem;
  margin-top: -.2rem;
}

.input-error ul, .input-help ul {
  margin: .2em 0 0 1.5em;
  list-style-type: disc;
}

.checkbox + .input-error, .checkbox + .input-help {
  border-radius: .25rem;
  margin-top: .25em;
}

.input-error:empty, .error {
  display: none;
}

.input-help {
  text-align: left;
  background: var(--subtle);
  color: var(--background-color);
  z-index: 1;
  padding: .5em;
  font-size: .6em;
  font-weight: bold;
  display: block;
}

.checkbox {
  position: relative;
}

.checkbox input {
  opacity: 0;
  position: absolute;
  top: 0;
}

.checkbox label {
  align-items: flex-start;
  display: flex;
}

.checkbox label:before {
  content: "";
  background: #fff;
  border-radius: .25rem;
  width: 4vw;
  height: 4vw;
  margin-right: 1vw;
  display: block;
  position: relative;
  top: .15em;
}

.checkbox label:after {
  content: "";
  border: .2em solid #fff;
  border-width: 0 .2em .2em 0;
  width: .5em;
  height: 1em;
  font-size: 2.3vw;
  position: absolute;
  top: .5em;
  left: .5em;
  transform: rotate(45deg);
}

.checkbox input:checked + label:before {
  background: var(--text-color);
}

.checkbox input:checked + label:after {
  border-color: var(--background-color);
}

.modal {
  color: #000;
  z-index: 200;
  background: #fff;
  border-radius: .25rem;
  padding: 2vw;
  font-size: 2vw;
  position: fixed;
  inset: 5%;
  overflow: auto;
  box-shadow: 0 0 5vw #0003;
}

.modal-content {
  margin: 4vh 0;
}

.modal.hidden {
  display: none;
}

.modal p, .modal h1, .modal h2 {
  margin-bottom: 1rem;
}

.modal ul {
  margin: 0 1rem 2rem;
}

.hg-theme-default .hg-button {
  padding: 1em 0 !important;
  font-size: 4vw !important;
}

.offline {
  z-index: 300;
  text-align: center;
  -webkit-backdrop-filter: blur(10px);
  color: var(--text-color);
  opacity: 0;
  background: #1f2a36e6;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
  transform: translate(0, 100%);
}

.offline.visible {
  opacity: 1;
  transition: opacity .3s;
  transform: translate(0);
}
/*# sourceMappingURL=index.ae99edca.css.map */
